<template>
    <div class="wrapper-container">
<!--        <common-header></common-header>-->
        <div class="register-container">
            <div class="appName">注册台医通</div>
            <div>
                <div class="inp-container">
                    <div class="wrapper wrapper-1 hide"><input v-model="loginInfo.phoneNum" placeholder="手机号" class="inp inp-user" type="text"></div>
                    <div class="password-container">
                        <div class="wrapper wrapper-2 hide"><input :type="isClose ? 'password' : 'text'" v-model="loginInfo.password" placeholder="密码" class="inp inp-password"></div>
                        <span @click="toggleEye" class="eye" :class="isClose ? 'close-eye' : ''"></span>
                    </div>
                </div>
                <div class="tip tip-password">密码必须至少8个字符，而且同时包含字母和数字</div>
            </div>
            <div class="login" @click="register">注册</div>
        </div>
    </div>
</template>
<script>
import {validateTel, validatePassword} from '@/utils/tools'
// import CommonHeader from '@/components/header.vue'
export default {
    data() {
        return {
            isClose: false,
            loginInfo: {
                phoneNum: '',
                password: ''
            }
        }
    },
    components: {
        // CommonHeader
    },
    mounted() {
        // window.addEventListener("KeyboardDown",function() {
        //     scrollToPre()
        // },false);
    },
    methods: {
        register() {
            if(!validateTel(this.loginInfo.phoneNum) || !validatePassword(this.loginInfo.password)) {
                this.$toast({
                    message: '请按照正确格式输入手机号和密码',
                    position: 'top'
                })
                return
            }
            let params = {
                telephone: this.loginInfo.phoneNum,
                password: this.$getRsaCode(this.loginInfo.password)
            }
            // 注册
            this.$store.dispatch('register', params).then((res) => {
                // 注册成功跳转到首页
                if(res.code !== 200) {
                    this.$toast({
                        message: res.msg
                    })
                }else {
                    // 注册成功 存储token
                    localStorage.setItem('user_token', res.data.token)
                    this.$toast({
                        message: '注册成功，将自动跳转到台医通首页'
                    })
                    setTimeout(() => {
                        this.$router.push('/')
                    }, 2000)
                }

            })
        },
        toggleEye() {
            this.isClose = !this.isClose
        }
    }
}
</script>
<style lang="scss" scoped>
    .wrapper-container {
        min-height: 100vh;
        background: #fff;
    }

    .register-container {
        width: 100%;
        padding: 128px 40px 0 35px;
        box-sizing: border-box;
        background: #fff;

        .to-register {
            color: #00C2C1;
            font-size: 16px;
            position: absolute;
            top: 22px;
            right: 22px;
        }
        .appName {
            font-size: 28px;
            color: #02BCBD;
            font-family: PingFangSC-bold;
            margin-bottom: 60px;
        }

        .tip {
            font-size: 18px;
            color: #ccc;
            font-family: PingFangSC-regular;
            text-align: left;
            height: 40px;

            &-password {
                font-size: 14px;
            }
        }

        .inp-container {
            display: flex;
            flex-direction: column;

            .wrapper {
                display: flex;
                align-items: center;
                position: relative;
                &-1::before {
                    content: '';
                    display: block;
                    width: 20px;
                    height: 20px;
                    background: url('../../assets/images/login-user.png');
                    background-size: 100%;
                    position: absolute;
                }

                &-2::before {
                    content: '';
                    display: block;
                    width: 24px;
                    height: 24px;
                    background: url('../../assets/images/login-lock.png');
                    background-size: 100%;
                    position: absolute;
                }

                &.hide {
                    .inp {
                        padding-left: 0;
                    }
                    &::before {
                        background: none;
                    }
                }

                .inp {
                    width: 300px;
                    height: 44px;
                    border-bottom: 1px solid #eee;
                    padding-left: 32px;
                    box-sizing: border-box;
                    font-size: 18px;
                    color: #101010;
                }
            }

            .password-container {
                position: relative;
                .eye {
                    display: block;
                    width: 24px;
                    height: 24px;
                    background: url('../../assets/images/visibility.png');
                    background-size: 100%;
                    position: absolute;
                    right: 0;
                    top: 10px;
                    &.close-eye {
                        background: url('../../assets/images/unVisibility.png');
                        background-size: 100%;
                    }
                }
            }
        }

        .login {
            width: 210px;
            height: 40px;
            line-height: 40px;
            font-size: 20px;
            color: #fff;
            background: #02BCBD;
            margin: 100px auto 0;
            border-radius: 20px;
        }

        .read {
            margin-top: 50px;
            display: flex;
            width: 300px;
            .choose {
                width: 20px;
                height: 20px;
                border: 1px solid #ccc;
                display: block;
                border-radius: 50%;
                margin-right: 2px;

                &.active {
                    background: url('../../assets/images/checked.png');
                    background-size: 100%;
                    border: none;
                }
            }

            .text {
                text-align: left;
                .protocol {
                    color: #02BCBD;
                }
            }
        }

    }
</style>
